import PropTypes from 'prop-types';

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import FormHelperText from '@mui/material/FormHelperText';

import { Form } from "../../utils/useForm";

import styles from "./UserForm.module.css";

const UserForm = ({
  values, handleInputChange, userFormWidth, closeUserForm,
  onSubmit, formErrors, isCreateBtnVisible, isEditBtnVisible,
}) => {
  return(
    <div className={styles.filterBox} style={{ width: userFormWidth }}>
      <Form onSubmit={onSubmit}>
        <Grid container className={styles.filterBoxItemTitle}>
          <Typography 
            component="h5" 
            variant="h5" 
            color={'text.secondary'}
          >
            <strong>
              User {values.userId === '' ? 'Create': 'Edit'}
            </strong>
          </Typography>
          <CloseIcon className={styles.closebtn} onClick={closeUserForm} />
        </Grid>

        <Grid container className={styles.filterBoxItem}>
          <TextField
            varient="outlined"
            label="First Name"
            name='firstName'
            value={values.firstName}
            onChange={handleInputChange('firstName')}
            style={{backgroundColor: 'white', width: '98%', marginTop: '10px'}}
            helperText={formErrors.firstName}
            error={'firstName' in formErrors}
          />
        </Grid>

        <Grid container className={styles.filterBoxItem}>
          <TextField
            varient="outlined"
            label="Last Name"
            name='lastName'
            value={values.lastName}
            onChange={handleInputChange('lastName')}
            style={{backgroundColor: 'white', width: '98%', marginTop: '10px' }}
            helperText={formErrors.lastName}
            error={'lastName' in formErrors}
          />
        </Grid>

        <Grid container className={styles.filterBoxItem}>
          <TextField
            varient="outlined"
            label="E-mail"
            name='email'
            type="email"
            value={values.email}
            onChange={handleInputChange('email')}
            style={{backgroundColor: 'white', width: '98%', marginTop: '10px'}}
            helperText={formErrors.email}
            error={'email' in formErrors}
          />
        </Grid>

        <Grid container className={styles.filterBoxItem} style={{ width: '98%', marginTop: '10px'}}>
          <FormControl fullWidth error={'organisation' in formErrors}>
            <InputLabel id="organisation">Organisation</InputLabel>
            <Select
              labelId="organisation-label"
              id="organisation"
              value={values.organisationId}
              label="Organisation"
              name="organisationId"
              onChange={handleInputChange('organisationId')}
            >
              {values.organisationList.map((data, index) => (
                <MenuItem key={index} value={data.id}>{data.name}</MenuItem>
              ))}
            </Select>
            <FormHelperText>{formErrors.organisation}</FormHelperText>
          </FormControl>
        </Grid>

        <Grid container className={styles.filterBoxItem} style={{ width: '98%', marginTop: '10px'}}>
          <FormControl fullWidth error={'role' in formErrors}>
            <InputLabel id="role">Role</InputLabel>
            <Select
              labelId="role-label"
              id="role"
              value={values.selectedRoleId}
              label="Role"
              name="selectedRoleId"
              onChange={handleInputChange('selectedRoleId')}
            >
              {values.rolesList.map((data, index) => (
                <MenuItem key={index} value={data.id}>{data.name}</MenuItem>
              ))}
            </Select>
            <FormHelperText>{formErrors.role}</FormHelperText>
          </FormControl>
        </Grid>

        <Grid container className={styles.filterBoxItemTitle}>
          {((values.userId === '' && isCreateBtnVisible) || 
            (values.userId !== '' && isEditBtnVisible)) && 
            <Button 
              variant="contained" 
              type="submit" 
              color="success"
            >
              Submit
            </Button>}
        </Grid>
      </Form>
    </div>
  );
};

UserForm.propTypes = {
  values: PropTypes.object.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  userFormWidth: PropTypes.number.isRequired,
  closeUserForm: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  formErrors: PropTypes.object.isRequired,
  isCreateBtnVisible: PropTypes.bool.isRequired,
  isEditBtnVisible: PropTypes.bool.isRequired,
};

export default UserForm;