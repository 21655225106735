import PropTypes from 'prop-types';

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";

import { Form } from "../../utils/useForm";

const FileTypesForm = ({ 
  values, handleClick, selectedFileTypes, submitForm, 
  formErrors, isSubmitBtnVisible,  
}) => {  
  return(
    <Form onSubmit={submitForm} style={{padding: '20px'}}>
      <Typography 
        component="h5" 
        variant="h5" 
        color={'text.secondary'}
        sx={{
          margin: 1.5
        }}
      >
        <strong>File Types</strong>
      </Typography>

      <Grid container spacing={1} sx={{ marginLeft: 1}}>
        {values.fileTypes.map((data, index) => (
          <Grid item xs={3} key={index}>
            <FormControlLabel
              control={
                <Checkbox
                  name={data.file_type}
                  value={data.id}
                  id={`inline-${data.id}-1`}
                  checked={selectedFileTypes.includes(data.id)}
                  onChange={handleClick}
                />
              }
              label={<Typography style={{ color: '#6c757d' }}>{data.file_type}</Typography>}
            />
          </Grid>
      ))}
      </Grid>

      {
        'fileType' in formErrors && 
        <span style={{
          color: '#d32f2f',
          fontSize: '12px',
          marginLeft: '10px',
        }}>
          {formErrors.fileType}
        </span>
      }
      
      <Grid container style={{ marginLeft: '10px', marginTop: '10px'}}>
        {isSubmitBtnVisible && <Button 
          variant='contained' 
          type='submit'
          color="success"
        >
          Submit
        </Button>}
      </Grid>
    </Form>
  );
};

FileTypesForm.propTypes = {
  values: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
  selectedFileTypes: PropTypes.array.isRequired,
  submitForm: PropTypes.func.isRequired,
  formErrors: PropTypes.object.isRequired,
  isSubmitBtnVisible: PropTypes.bool.isRequired,
};

export default FileTypesForm;