import { useState, useContext, useEffect } from "react";

import { resObj } from "../../utils/constants";
import useAxios from "../../utils/useAxios";
import NotificationContext from "../../context/NotificationContext";
import PermissionContext from "../../context/PermissionContext";
import DrawerContext from "../../context/DrawerContext";
import AuthContext from "../../context/AuthContext";
import AutoLogout from "../../utils/useAutoLogout";

import SideDrawer from "../../components/layout/SideDrawer";
import FileDropZone from "../../components/layout/FileDropZone";
import PageHeader from "../../components/layout/PageHeader";
import ErrorModal from "../../components/layout/ErrorModal";
import SuccessAlert from "../../components/layout/SuccessAlert";
import Loader from "../../components/layout/Loader";
import InfoModal from "../../components/layout/InfoModal";

const ImportDataPage = () => {
  const api = useAxios();

  const { getNotificationCount } = useContext(NotificationContext);
  const { permisionsList, checkUserPermission } = useContext(PermissionContext);
  const { drawerValue, setDrawerValue } = useContext(DrawerContext);
  const ORGANISATION = useContext(AuthContext)?.user?.organisation;

  const [apiRes, setApiRes] = useState(resObj);
  const [showInfo, setShowInfo] = useState(false);
  const initialValues = {
    file: null,
    dataType: '',
    selectedOption: null,
    ownedBy: ORGANISATION,
  };
  const [uploadInitValues, setUploadInitValues] = useState(initialValues);
  const [fileTypeList, setFileTypeList] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const organisationsList = ['CPI', 'MDC', 'UoS', 'UoL'];

  const handleSubmit = () => {
    setFormErrors(validate());
    setIsSubmit(true);
  };

  const validate = () => {
    const errors = {};
    if (!uploadInitValues.file) {
      errors.file = 'File is required!';
    }
    if (!uploadInitValues.dataType) {
      errors.dataType = 'Type is required!';
    }
    if (!uploadInitValues.ownedBy) {
      errors.ownedBy = 'Owned by is required!';
    }
    return errors;
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      uploadFile();
    }
  }, [formErrors]);

  const uploadFile = async () => {
    try {
      setApiRes({...apiRes, loading: true});
      const formData = new FormData();
      formData.append("file", uploadInitValues?.file);
      formData.append("data_type", uploadInitValues?.dataType);
      formData.append("owned_by", uploadInitValues?.ownedBy);

      const response = await api.post('/api/transfer/upload/file', formData);

      if (response.status === 200) {
        setApiRes({
          ...apiRes,
          showAlert: true,
          successMsg: response.data,
          loading: false,
        });
        setUploadInitValues(initialValues);
        getNotificationCount();
      }
    } catch (err) {
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data).replace(/[{}[\]"']/g, ''),
        errHeading: 'Upload File',
        loading: false,
      });
    }
  };

  useEffect(() => {
    if(permisionsList.length > 0) checkUserPermission(['Analytics-Import Data']);
  }, [permisionsList]);

  useEffect(() => {
    getFileTypes();
  }, []);

  const getFileTypes = async () => {
    try {
      setApiRes({...apiRes, loading: true});
      const response = await api.get(
        '/api/user/organisation/organisation/file-types/get'
      );

      setApiRes({...apiRes, loading: false});      
      if (response.status === 200) {
        setFileTypeList(response.data);
      }
    } catch (err){
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data).replace(/[{}[\]"']/g, ''),
        errHeading: 'File Types Get',
      });
    }
  };

  return(
    <AutoLogout>
      {apiRes.loading && <Loader />}
      <SideDrawer drawerValue={drawerValue} setDrawerValue={setDrawerValue} />
      <div className="mainContainer" style={{ marginLeft: drawerValue.drawerWidth }}>
        {apiRes.showAlert && <SuccessAlert apiRes={apiRes} setApiRes={setApiRes} />}
        <PageHeader title={'Import Data'} />

        <FileDropZone 
          uploadInitValues={uploadInitValues}
          setUploadInitValues={setUploadInitValues}
          fileTypeList={fileTypeList}
          organisation={ORGANISATION}
          organisationsList={organisationsList}
          apiRes={apiRes} 
          setApiRes={setApiRes} 
          setShowInfo={setShowInfo} 
          formErrors={formErrors} 
          onSubmit={handleSubmit}
        />

        <InfoModal showInfo={showInfo} setShowInfo={setShowInfo} />
        <ErrorModal apiRes={apiRes} setApiRes={setApiRes} />
      </div>
    </AutoLogout>
  );
};

export default ImportDataPage;