import { useEffect, useState, useContext } from "react";

import useAxios from "../../utils/useAxios";
import { resObj } from "../../utils/constants";
import PermissionContext from "../../context/PermissionContext";
import DrawerContext from "../../context/DrawerContext";
import AutoLogout from "../../utils/useAutoLogout";
import { useForm } from "../../utils/useForm";

import SideDrawer from "../../components/layout/SideDrawer";
import Loader from "../../components/layout/Loader";
import SuccessAlert from "../../components/layout/SuccessAlert";
import ErrorModal from "../../components/layout/ErrorModal";
import TopBarWithBack from "../../components/layout/TopBarWithBack";
import FileTypesForm from "../../components/user_mngmnt/FileTypesForm";

const OrganisationEditPage = () => {
  const api = useAxios();
  const { permisionsList, checkUserPermission } = useContext(PermissionContext);
  const { drawerValue, setDrawerValue } = useContext(DrawerContext);

  const backURL = '/organisations';
  const docUrIArr = window.location.href.split('/');
  const organisationId = docUrIArr[docUrIArr.length - 1];

  useEffect(() => {
    if(permisionsList.length > 0) checkUserPermission(['Admin-Manage Organisation']);
  }, [permisionsList]);

  const initialValues = {
    organisationName: '',
    fileTypes: [],
  };

  const [apiRes, setApiRes] = useState(resObj);
  const {values, setValues} = useForm(initialValues);
  const [selectedFileTypes, setSelectedFileTypes] = useState([]);

  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    setFormErrors(validate(values));
    setIsSubmit(true);
  };

  const validate = values => {
    const errors = {};
    if (selectedFileTypes.length === 0) {
      errors.fileType = 'File type is required!';
    }
    return errors;
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      organisationEdit();
    }
  }, [formErrors]);
  
  const handleClick = e => {
    const fileTypeId = e.target.value;
    if (e.target.checked) {
      setSelectedFileTypes(arr => [...arr, +fileTypeId]);
    } else {
      setSelectedFileTypes(arr => arr.filter(val => val !== +fileTypeId));
    }
  };

  useEffect(() => {
    getOrganisationDetails();
  }, []);

  const getFileTypes = async repsonseData => {
    try {
      setApiRes({...apiRes, loading: true});
      const response = await api.get('/api/user/organisation/file-types/get');

      setApiRes({...apiRes, loading: false});
      if (response.status === 200) {        
        setValues({
          ...values,
          organisationName: repsonseData.name,
          fileTypes: response.data,
        });
      }
    } catch (err){
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data).replace(/[{}[\]"']/g, ''),
        errHeading: 'File Types Get',
      });
    }
  };

  const getOrganisationDetails = async () => {
    try {
      setApiRes({...apiRes, loading: true});
      const response = await api.get('/api/user/organisation/details/get', {
        params: { organisation_id: organisationId }
      });
      
      if (response.status === 200) {        
        setSelectedFileTypes(response.data.file_types_list);
        getFileTypes(response.data);
        setApiRes({ ...apiRes, loading: false });
      }
    } catch (err){
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data),
        errHeading: 'Organisation Details Get',
        loading: false,
      });
    }
  };

  const organisationEdit = async () => {
    try {
      setApiRes({...apiRes, loading: true});
      const response = await api.post('/api/user/organisation/edit', {
        organisation_id: organisationId,
        file_types_list: JSON.stringify(selectedFileTypes),
      });

      if (response.status === 200) {
        setApiRes({
          ...apiRes,
          showAlert: true,
          successMsg: response.data,
          loading: false,
        });
      }
    } catch (err) {
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data),
        errHeading: 'Organisation Edit',
        loading: false,
      });
    }
  };

  return(
    <AutoLogout>
      <SideDrawer drawerValue={drawerValue} setDrawerValue={setDrawerValue} />
      {apiRes.loading && <Loader />}
      <div className="mainContainer" style={{ marginLeft: drawerValue.drawerWidth }}>
        {apiRes.showAlert && <SuccessAlert apiRes={apiRes} setApiRes={setApiRes} />}
        <TopBarWithBack title={values.organisationName} backURL={backURL} />

        <FileTypesForm 
          values={values}
          handleClick={handleClick}
          selectedFileTypes={selectedFileTypes}
          submitForm={handleSubmit}
          formErrors={formErrors}
          isSubmitBtnVisible={permisionsList.includes('Admin-Manage Organisation')}
        />

        <ErrorModal apiRes={apiRes} setApiRes={setApiRes} />
      </div>
    </AutoLogout>
  );
};

export default OrganisationEditPage;