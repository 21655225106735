import { useState, useEffect, useContext, useRef } from "react";

import { resObj, paginationVal } from "../../utils/constants";
import { useForm } from "../../utils/useForm";
import useAxios from "../../utils/useAxios";
import PermissionContext from "../../context/PermissionContext";
import DrawerContext from "../../context/DrawerContext";
import AutoLogout from "../../utils/useAutoLogout";

import Pagination from '@mui/material/Pagination';

import SideDrawer from "../../components/layout/SideDrawer";
import ListHeader from "../../components/layout/ListHeader";
import NoData from "../../components/layout/NoData";
import Loader from "../../components/layout/Loader";
import UserForm from "../../components/user_mngmnt/UserForm";
import BackDrop from "../../components/layout/BackDrop";
import ErrorModal from "../../components/layout/ErrorModal";
import DeleteModal from "../../components/layout/DeleteModal";
import SuccessAlert from "../../components/layout/SuccessAlert";
import UserTable from "../../components/user_mngmnt/UserTable";

const UserListPage = () => {
  const api = useAxios();
  const { permisionsList, checkUserPermission } = useContext(PermissionContext);
  const { drawerValue, setDrawerValue } = useContext(DrawerContext);

  useEffect(() => {
    if(permisionsList.length > 0) checkUserPermission(['Admin-User Create', 'Admin-Manage User', 'Admin-View User List']);
  }, [permisionsList]);

  const tableHeading = ['SI No', 'Full Name', 'E-mail', 'Organisation', 'Role'];

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    organisationId: '',
    userId: '',
    selectedRoleId: '',
    rolesList: [],
    organisationList: [],
  };

  const pageData = useRef({...paginationVal});
  const {values, setValues, handleInputChange} = useForm(initialValues);
  const [apiRes, setApiRes] = useState(resObj);
  const [userList, setUserList] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [userFormWidth, setUserFormWidth] = useState(0);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [userId, setUserId] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const deleteModalTitle = 'User Delete';
  const deleteModalMessage = `Are you sure you want to delete? 
    (All related informations will be lost)`;

  const navigateToUserEdit = userId => {
    const res = userList.filter(function (el) {
      return el.id === userId
    })[0];

    setValues({
      ...values, 
      userId: userId,
      firstName: res.full_name.split(' ')[0],
      lastName: res.full_name.split(' ')[1],
      email: res.email,
      organisationId: res.organisation_id,
      selectedRoleId: res.role_id,
    });
    openUserForm();
  };

  const openUserForm = () => {
    setModalIsOpen(true);
    setUserFormWidth(350);
  };

  const closeUserForm = () => {
    setModalIsOpen(false);
    setUserFormWidth(0);
    setValues({
      ...values,
      userId: '',
      firstName: '',
      lastName: '',
      email: '',
      organisationId: '',
      selectedRoleId: '',
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    setFormErrors(validate(values));
    setIsSubmit(true);
  };

  const validate = values => {
    const errors = {};
    const namePattern = /^[a-zA-Z\s-]+$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const maxLength = 50;

    if (!values.firstName) {
      errors.firstName = 'First Name is required!';
    } else if (!namePattern.test(values.firstName)) {
      errors.firstName = 'First Name can only contain letters, spaces, and hyphens!';
    } else if (values.firstName.length > maxLength) {
      errors.firstName = `First Name cannot be longer than ${maxLength} characters!`;
    }

    if (!values.lastName) {
      errors.lastName = 'Last Name is required!';
    } else if (!namePattern.test(values.lastName)) {
      errors.lastName = 'Last Name can only contain letters, spaces, and hyphens!';
    } else if (values.lastName.length > maxLength) {
      errors.lastName = `Last Name cannot be longer than ${maxLength} characters!`;
    }

    if (!values.email) {
      errors.email = 'Email is required!';
    } else if (!emailRegex.test(values.email)) {
      errors.email = 'This is not a valid email format!';
    }

    if (!values.organisationId) {
      errors.organisation = 'Organisation is required!';
    } 
    
    if (!values.selectedRoleId) {
      errors.role = 'Role is required!';
    }
    return errors;
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      submitForm();
    }
  }, [formErrors]);

  const getAllRoles = async () => {
    try {
      setApiRes({...apiRes, loading: true});
      const response = await api.get('/api/all/roles/get');
      
      if (response.status === 200) {
        setApiRes({...apiRes, loading: false});
        setValues({
          ...values,
          rolesList: response.data.roles,
          organisationList: response.data.organisations,
        });
      }
    } catch (err){
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data).replace(/[{}[\]"']/g, ''),
        errHeading: 'Roles Get',
        loading: false,
      });
    }
  };

  const submitForm = () => {
    if (values.userId === '') {
      userCreate();
    } else {
      userEdit();
    }
  }

  const userCreate = async () => {
    try {
      setApiRes({...apiRes, loading: true});
      const response = await api.post('/api/user/create', {
        first_name: values?.firstName,
        last_name: values?.lastName,
        username: values?.email,
        email: values?.email,
        organisation_id: values?.organisationId,
        role_id: values?.selectedRoleId,
      });

      if (response.status === 200) {
        getUsers('');
        setApiRes({
          ...apiRes,
          showAlert: true,
          successMsg: response.data,
          loading: false,
        });
      }
    } catch (err) {
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data).replace(/[{}[\]"']/g, ''),
        errHeading: 'User Create',
        loading: false,
      });
    }
    closeUserForm();
  };

  const userEdit = async () => {
    try {
      setApiRes({...apiRes, loading: true});
      const response = await api.post('/api/user/edit', {
        user_id: values?.userId,
        first_name: values?.firstName,
        last_name: values?.lastName,
        username: values?.email,
        email: values?.email,
        organisation_id: values?.organisationId,
        role_id: values?.selectedRoleId,
      });

      if (response.status === 200) {
        getUsers('');
        setApiRes({
          ...apiRes,
          showAlert: true,
          successMsg: response.data,
          loading: false,
        });
      }
    } catch (err) {
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data).replace(/[{}[\]"']/g, ''),
        errHeading: 'User Create',
        loading: false,
      });
    }
    closeUserForm();
  };

  useEffect(() => {
    getAllRoles();
    getUsers();
  }, []);

  const getUsers = async () => {
    try {
      setApiRes({...apiRes, loading: true});
      const response = await api.get('/api/user/list/get', {
        params: { 
          search_term: pageData.current.searchTerm,
          page_number: +pageData.current.pageNumber,
        }
      });
      
      if (response.status === 200) {        
        setApiRes({ ...apiRes, loading: false });        
        setUserList(response.data.data);
        pageData.current.pageCount = response.data.page_count;
      }
    } catch (err){
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data).replace(/[{}[\]"']/g, ''),
        errHeading: 'Users Get',
        loading: false,
      });
    }
  };

  const deleteUser = async () => {
    try {
      setApiRes({...apiRes, loading: true});
      const response = await api.delete(`/api/user/delete/${userId}`);

      if (response.status === 200) {
        getUsers();
        setDeleteModalOpen(false);
        setApiRes({
          ...apiRes,
          showAlert: true,
          successMsg: 'User deleted successfully',
          loading: false,
        });
      }
    } catch (err) {
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data).replace(/[{}[\]"']/g, ''),
        errHeading: 'User Delete',
        loading: false,
      });
    }
  };

  const handlePaginationClick = (e, pageNumber) => {
    pageData.current.pageNumber = pageNumber;
    getUsers();
  };

  const handleLockUser = async (e, userId) => {
    try {
      setApiRes({...apiRes, loading: true});
      const response = await api.post('/api/user/toggle/lock', {
        user_id: userId,
        is_locked: e.target.checked,
      });

      if (response.status === 200) {
        getUsers('');
        setApiRes({
          ...apiRes,
          showAlert: true,
          successMsg: response.data,
          loading: false,
        });
      }
    } catch (err) {
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data).replace(/[{}[\]"']/g, ''),
        errHeading: 'Lock / Unlock User',
        loading: false,
      });
    }
    closeUserForm();
  };

  return(
    <AutoLogout>
      <SideDrawer drawerValue={drawerValue} setDrawerValue={setDrawerValue} />
      {apiRes.loading && <Loader />}
      <div className="mainContainer" style={{ marginLeft: drawerValue.drawerWidth }}>
        {apiRes.showAlert && <SuccessAlert apiRes={apiRes} setApiRes={setApiRes} />}
        <ListHeader 
          title="Users" 
          handleClick={openUserForm} 
          search={getUsers} 
          pageData={pageData}
          hasCreatePermission={permisionsList.includes('Admin-User Create')}
        />

        {modalIsOpen && <UserForm
          values={values}
          handleInputChange={handleInputChange}
          userFormWidth={userFormWidth}
          closeUserForm={closeUserForm}
          onSubmit={handleSubmit}
          formErrors={formErrors}
          isCreateBtnVisible={permisionsList.includes('Admin-User Create')}
          isEditBtnVisible={permisionsList.includes('Admin-Manage User')}
        />}

        {userFormWidth === 350 && <BackDrop />}

        <UserTable 
          heading={tableHeading} 
          rows={userList} 
          navigateToEdit={navigateToUserEdit}
          setId={setUserId}
          setDeleteModalOpen={setDeleteModalOpen}
          pageData={pageData}
          idKey={'id'}
          showToolTip={false}
          handleLockUser={handleLockUser}
          hasManagePermission={permisionsList.includes('Admin-Manage User')}
        />

        {(userList.length !== 0 && pageData.current.pageCount !== 1) && <Pagination 
                                    style={{marginTop: '10px', float: 'right'}} 
                                    count={pageData.current.pageCount} 
                                    onChange={handlePaginationClick}/>}

        {userList.length === 0 && <NoData />}

        <ErrorModal apiRes={apiRes} setApiRes={setApiRes} />

        <DeleteModal
          title={deleteModalTitle} 
          message={deleteModalMessage} 
          open={deleteModalOpen}
          setOpen={setDeleteModalOpen}
          onDelete={deleteUser}
        />
      </div>
    </AutoLogout>
  );
};

export default UserListPage;